<template>
  <ValidationObserver tag="div" class="location-show" ref="validationObserver" v-slot="{ pristine, valid }">
    <div class="main-content container">
      <div class="top-actions">
        <UIButton
          tiny
          icon="trash"
          v-if="location.id"
          @click="deleteLocation"
          class="delete-location"
        >{{ $t("Delete Location") }}</UIButton>
      </div>

      <ContentBlock :required="true" :loading="loading">
        <div slot="instructions">
          <h5>{{ $t('Basic Info') }}</h5>
          <p>{{ $t('Customers will use this information to find and contact your location.') }}</p>
        </div>
        <ValidationProvider ref="tempValidationProvider" rules="required">
          <input v-model="location.title" type="hidden">
        </ValidationProvider>
        <div class="name-and-address mb4">
          <UIInput
            :title="$t('Location Name')"
            v-model="location.title"
            required
            rules="required"
          />
          <AddressInputs
            v-if="countries"
            :countries="countries"
            :regions="regions"
            :fullAddress="fullAddress"
            @update:fullAddress="onUpdateFullAddress"
          />
        </div>
        <div class="time-and-language">
          <UISelect :title="$t('Time Zone')" v-model="location.timezone" required rules="dropdown">
            <option
              v-for="timezone in timezones"
              :value="timezone.key"
              :key="timezone.key"
            >{{ timezone.title }}</option>
          </UISelect>
          <UISelect :title="$t('Time Format')" v-model="location.twentyFourHourTime">
            <option :value="false">{{ $t('Twelve Hour Time') }}</option>
            <option :value="true">{{ $t('Twenty Four Hour Time') }}</option>
          </UISelect>
          <UISelect :title="$t('Language / Locale')" v-model="location.locale" required rules="required|dropdown">
            <option
              v-for="locale in locales"
              :value="locale.key"
              :key="locale.key"
            >{{ locale.text }}</option>
          </UISelect>
        </div>
      </ContentBlock>

      <ContentBlock :required="true" :loading="loading">
        <div slot="instructions">
          <h5>{{ $t('Contact Info') }}</h5>
          <p>{{ $t('This is your public facing contact info. This is the info that will be provided in email confirmations to your customers.') }}</p>
        </div>
        <div class="contact">
          <UIInput
            :title="$t('Email')"
            v-model="location.email"
            required
            rules="required|email"
          />
          <UIInput
            :title="$t('Phone')"
            v-model="location.phone"
            rules="phone"
          />
        </div>
      </ContentBlock>

      <ContentBlock v-if="!$permissions['education']" :loading="loading">
        <div slot="instructions">
          <h5>{{ $t('Currency & Tax Settings') }}</h5>
          <p>{{ $t('This is where you can set your sales tax for your location.') }}</p>
        </div>
        <div class="currency-and-tax">
          <UISelect :title="$t('Currency')" v-model="location.currency" required rules="dropdown">
            <option
              v-for="currency in currencies"
              :value="currency.code"
              :key="currency.code"
            >{{ currency.name }}</option>
          </UISelect>
          <UISelect :title="$t('Tax Type')" v-model="location.taxExclusive">
            <option :value="true">{{ $t('Exclusive Tax') }}</option>
            <option :value="false">{{ $t('Inclusive Tax') }}</option>
          </UISelect>
          <UIInput
            :title="$t('Tax Amount (In Percent)')"
            v-model="location.taxAmount"
            type="number"
            rules="decimal:3|minValue:0|maxValue:99.999"
          />
        </div>
      </ContentBlock>

      <ContentBlock :required="true" :loading="loading">
        <div slot="instructions">
          <h5>{{ $t('Waiver settings') }}</h5>
          <p>{{ $t('SpringboardVR supports electronic waivers. Your customers will get a link in their confirmation e-mails, with which they can manage signing by the rest of the guests. Waivers are either of or on for the entire location.') }}</p>
        </div>
        <div class="waivers">
          <UISwitch
            :title="$t('Use waivers on this location')"
            :value="location.waiversRequired"
            @change="toggleRequireWaivers($event)"
          />
          <UIInput
            :title="$t('Manage waivers URL for host')"
            v-model="location.waiverHostProxyUrl"
          >
            <template #instructions>
              <i18n path="The URL of a page on your website, that displays our waiver management page in an iframe. Read the {setupInstructions}." tag="span">
                <template #setupInstructions><a class="link-style" href="https://support.springboardvr.com/hc/en-us/articles/1500009384401" target=_blank rel=noopener>{{ $t('Setup Instructions') }}</a></template>
              </i18n>
            </template>
          </UIInput>
          <UIInput
            :title="$t('Sign waiver URL for guests')"
            v-model="location.waiverGuestProxyUrl"
          >
            <template #instructions>
              <i18n path="The URL of a page on your website, that displays our sign waiver page in an iframe. Read the {setupInstructions}." tag="span">
                <template #setupInstructions><a class="link-style" href="https://support.springboardvr.com/hc/en-us/articles/1500009384401" target=_blank rel=noopener>{{ $t('Setup Instructions') }}</a></template>
              </i18n>
            </template>
          </UIInput>
        </div>
      </ContentBlock>

      <LocationBookingIntegrations
        v-if="!loading && location && !$permissions['education'] && location.integrations"
        :location="location"
        @update:integrations="setIntegrations"
      />
      <LocationGateways
        v-if="!loading && location && !$permissions['education'] &&  location.gateways && showPaymentOptions"
        :location="location"
        @updateGateways="updateGateways"
        @paypalExpanded="editingPaypalGateway = true"
        @paypalClosed="editingPaypalGateway = false"
        :ignoreValidationForParent="true"
      />
      <ContentBlock :loading="loading">
        <div slot="instructions">
          <h5>{{ $t('Free Roam (a.k.a. LBVR)') }}</h5>
          <p>{{ $t('SpringboardVR offers SpringboardVR Arena as a free roam service. Set up the service if you offer free roam play spaces.') }}</p>
        </div>
        <div class="additional-services">
          <UISwitch
            :title="$t('Use SpringboardVR Arena on this location')"
            :value="location.usesHaze"
            @change="toggleUsesHaze($event)"
          >
            <template #instructions>
              <i18n path="Download {clientLink} and {masterLink} and read the {setupInstructions}." tag="span">
                <template #clientLink><a class="link-style" href="https://storage.googleapis.com/desktop-storage.springboardvr.com/Demo/client-v3.0.28.exe" target=_blank rel=noopener>{{ $t('Client Arena') }}</a></template>
                <template #masterLink><a class="link-style" href="https://storage.googleapis.com/desktop-storage.springboardvr.com/Demo/master-v3.0.28.exe" target=_blank rel=noopener>{{ $t('Master Arena') }}</a></template>
                <template #setupInstructions><a class="link-style" href="https://support.springboardvr.com/hc/en-us/articles/11461238251411-Client-Machine-Setup-for-Arena" target=_blank rel=noopener>{{ $t('Setup Instructions') }}</a></template>
              </i18n>
            </template>
          </UISwitch>
        </div>
      </ContentBlock>
    </div>
    <BottomBar
      @save="storeLocation"
      :valid="!pristine && valid && !editingPaypalGateway"
      :saving="saving"
      :backRouterLink="{ name: 'location-index' }"
    />
    <UserPermissionModalWrapper ref="usermodal" />
  </ValidationObserver>
</template>

<script>
import FocusModeMixin from '@/core/shared/misc/FocusModeMixin';
import Component, { mixins } from 'vue-class-component';
import ContentBlock from '@/core/shared/components/ContentBlock';
import AddressInputs from '@/core/shared/components/AddressInputs';
import BottomBar from '@/core/shared/components/BottomBar';
import UIButton from '@/core/shared/components/ui/UIButton';
import UISelect from '@/core/shared/components/ui/UISelect';
import UIInput from '@/core/shared/components/ui/UIInput';
import UISwitch from '@/core/shared/components/ui/UISwitch';
import LocationBookingIntegrations from './components/LocationBookingIntegrations';
import LocationGateways from './components/LocationGateways';
import UserPermissionModal from '@/admin/location/components/UserPermissionModal';
import { isEqual } from 'lodash-es';
import { createModalWrapper } from '@/core/shared/helpers/HOCHelper';
import { emailPattern } from '@/core/shared/helpers/RegExpHelper';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

const UserPermissionModalWrapper = createModalWrapper(UserPermissionModal);

@Component({
  props: {
    editingLocationId: {
      type: String,
      required: true,
    },
  },
  components: {
    ContentBlock,
    AddressInputs,
    BottomBar,
    UIInput,
    UISelect,
    UISwitch,
    LocationGateways,
    UIButton,
    UserPermissionModalWrapper,
    LocationBookingIntegrations,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class LocationShow extends mixins(FocusModeMixin) {
  defaultLocation = {
    title: 'New Location',
    address: '',
    address2: '',
    city: '',
    state: '',
    postalCode: '',
    country: '',
    timezone: 'America/Los_Angeles',
    twentyFourHourTime: false,
    taxExclusive: true,
    taxAmount: 0,
    locale: 'en-US',
    currency: 'USD',
    gateways: [],
    waiversRequired: false,
    waiverHostProxyUrl: '',
    waiverGuestProxyUrl: '',
    usesHaze: false,
  };

  saving = false;
  locationChanges = {};
  editingPaypalGateway = false;

  get location () {
    if (!this.locationInStore) return {};

    // NOTE(Jack): This validates the form whenever this is grabbed, showing the state on each field
    if (this.$refs.validationObserver) this.$refs.validationObserver.validate();
    return new Proxy({ ...this.locationInStore, ...this.locationChanges }, {
      set: (object, property, value) => {
        if (isEqual(this.locationInStore[property], value)) {
          this.$delete(this.locationChanges, property, value);
        } else {
          this.$set(this.locationChanges, property, value);
        }
        this.$refs.validationObserver.validate();
        return true;
      },
    });
  }

  get locationInStore () {
    return (this.editingLocationId === 'new') ? {} : this.$store.getters['entities/Location'](this.editingLocationId);
  }

  get showPaymentOptions () {
    if (!this.location) return false;
    if (!this.location.integrations) return false;
    return this.location.integrations.find(integration => {
      return integration.type === 'springboardvr';
    });
  }

  updateGateways (gateways) {
    this.location.gateways = gateways;
  }

  setIntegrations (integrations) {
    // this is temporary solution to make save button available after modifying integrations multi-selector
    if (this.$refs.tempValidationProvider) {
      this.$refs.tempValidationProvider.setFlags({ pristine: false });
    }
    // console.log(integrations, this.location.integrations);
    this.location.integrations = [...integrations];
  }

  get bookingIntegrationValidity () {
    if (this.location.integrations) {
      return this.location.integrations.every(integration => {
        if (integration.type === 'springboardvr') {
          return true;
        } else {
          return integration.integrationSettings.every(integrationSetting => {
            return integrationSetting.value || integrationSetting.value === '[]';
          });
        }
      });
    }
    return true;
  }

  get loading () {
    return this.$store.state.admin.isFetchingLocations;
  }

  get organizationCountry () { return this.$store.getters.currentOrganization.country; }
  get timezones () { return this.$store.getters['entities/Timezone/all'](); }
  get currencies () { return this.$store.getters['entities/Currency/all'](); }
  get countries () { return this.$store.getters['entities/Country/all'](); }
  get regions () { return this.$store.getters['entities/Region/all'](); }
  get locales () { return this.$store.getters['entities/Locale/all'](); }
  get shouldDisplayPrompt () {
    return this.$store.getters['entities/User/all']().some(user => user.accessLevel !== 'admin');
  }

  async created () {
    if (this.timezones.length === 0) this.$store.dispatch('getTimezones');
    if (this.currencies.length === 0) this.$store.dispatch('getCurrencies');
    if (this.countries.length === 0) this.$store.dispatch('getCountries');
    if (this.regions.length === 0) this.$store.dispatch('getRegions');
    if (this.locales.length === 0) this.$store.dispatch('getLocales');

    this.$store.dispatch('getLocationsDetail');

    if (this.editingLocationId === 'new') {
      this.locationChanges = this.defaultLocation;
      await this.$store.dispatch('getUsers');
    }
  }

  get fullAddress () {
    const {
      address,
      address2,
      city,
      state,
      country,
      postalCode,
    } = this.location;
    return {
      address,
      address2,
      city,
      state,
      country,
      postalCode,
    };
  }

  toggleRequireWaivers (event) {
    this.locationChanges.waiversRequired = event.target.checked;
  }

  toggleUsesHaze (event) {
    this.locationChanges.usesHaze = event.target.checked;
  }

  onUpdateFullAddress ({ address, address2, city, state, country, postalCode }) {
    this.location.address = address;
    this.location.address2 = address2;
    this.location.city = city;
    this.location.state = state;
    this.location.postalCode = postalCode;
  }

  async storeLocation () {
    if (emailPattern.test(this.location.email)) {
      try {
        this.saving = true;
        const locationToSave = { ...this.locationChanges };
        if (this.location.id) locationToSave.id = this.location.id;

        await this.$store.dispatch('storeLocation', locationToSave);
        this.$store.commit('setFlash', {
          message: `${this.location.title} ${this.$t('saved')}`,
          type: 'success',
        });
        if (this.editingLocationId === 'new' && this.shouldDisplayPrompt) {
          this.$refs.usermodal.show(null, {
            closeModal: () => {
              this.$router.push({ name: 'location-index' });
            },
          });
        } else {
          this.$router.push({ name: 'location-index' });
        }
      } finally {
        this.saving = false;
      }
    } else {
      this.$store.commit('setFlash', {
        message: 'Invalid email',
        type: 'error',
      });
    }
  }

  deleteLocation () {
    this.$store.commit('setConfirm', {
      show: true,
      message: this.$t('Are you sure you would like to delete this Location? This will delete all experiences, stations, locations, discounts and coupons associated with this location.'),
      icon: 'icon-trash',
      buttons: [
        {
          name: this.$t('No'),
          type: 'cancel',
        },
        {
          name: this.$t('Yes'),
          action: async () => {
            await this.$store.dispatch('deleteLocation', this.location);
            this.$router.push({ name: 'location-index' });
          },
        },
      ],
    });
  }
}
</script>

<style scope lang="postcss">
@import "core/shared/styles";

.location-show {
  & .top-actions {
    display: flex;
    justify-content: flex-end;
    margin: var(--spacingMd) 0 var(--spacingSm) 0;

    @media (--desktop) {
      margin: var(--spacingMd) 0;
    }

    & .delete-location {
      margin: 0;
      margin-left: auto;
      width: auto;
    }
  }

  & .name-and-address {
    display: grid;
    grid-gap: var(--spacingSm);
  }

  & .time-and-language,
  & .currency-and-tax {
    display: grid;
    grid-gap: var(--spacingSm);

    @media (--tablet) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (--desktop) {
      grid-template-columns: repeat(3, 1fr);
      grid-gap: var(--spacingMd);
    }
  }

  & .waivers {
    display: grid;
    grid-gap: var(--spacingMd);
  }

  & .contact {
    display: grid;
    grid-gap: var(--spacingSm);

    @media (--tablet) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (--desktop) {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: var(--spacingMd);
    }
  }
}
</style>
